
import { Vue, Options } from "vue-class-component";
import CardDashboardClient from "@/app/ui/views/dashboard/component/client/component/card-dashboard-client.vue";
import DetailStatusCardDashboardClient from "@/app/ui/views/dashboard/component/client/component/detail-status-card-dashboard-client.vue";
import BulletPoint from "@/app/ui/components/icons/modules/bullet-point.vue";
import ProgressBar from "@/app/ui/components/progress-bar/index.vue";
import DonutChart from "@/app/ui/components/donut-chart/index.vue";
import { DashboardController } from "@/app/ui/controllers/DashboardController";
import { DataCorporateDashboard } from "@/domain/entities/Dashboard";

@Options({
  components: {
    BulletPoint,
    DetailStatusCardDashboardClient,
    CardDashboardClient,
    ProgressBar,
    DonutChart
  }
})
export default class SectionShippingCostDashboard extends Vue {
  mounted() {
    this.initCorporateShippingCostPerStatus();
  }
  // total shipping cost
  get totalShippingCost() {
    let amount = 0;
    let resi = 0;
    let loading = 0;
    this.shippingCostPerStatus.forEach((item: any) => {
      amount += item.amount;
      resi += item.resi;
      loading += item.loading;
    });
    return {
      title: "Total",
      type: "total_shippping_cost",
      amount: amount,
      resi: resi,
      loading: !!loading
    };
  }
  async initCorporateShippingCostPerStatus() {
    let delay = 0;
    const delayIncrement = 250;
    const requestCorporateDashboardShippingCost = this.shippingCostPerStatus.map(
      (delivery: any) => {
        delivery.loading = true;
        delay += delayIncrement;
        return new Promise(resolve => {
          setTimeout(resolve, delay);
        }).then(() => {
          DashboardController.getCorporateDashboardDeliveryData({
            path: "/delivery/amount/status",
            sttCategory: delivery.type,
            cache: true
          }).then((resp: DataCorporateDashboard) => {
            delivery.loading = false;
            setTimeout(() => {
              delivery.resi = resp.totalStt;
              delivery.amount = resp.totalTariffAmount;
            }, 100);
          });
        });
      }
    );
    await Promise.all(requestCorporateDashboardShippingCost);
  }

  donutChartTotal = 0;
  get chartData() {
    this.donutChartTotal++;
    return [
      this.shippingCostPerStatus[0].resi,
      this.shippingCostPerStatus[1].resi,
      this.shippingCostPerStatus[2].resi
    ];
  }
  chartColor = ["#F05C72", "#FFCB01", "#7ACB90"];

  // shipping cost per status
  shippingCostPerStatus: Array<any> = [
    {
      title: "Akan Ditagihkan",
      type: "IN_PROGRESS",
      amount: 0,
      resi: 0,
      loading: false,
      color: "#F05C72"
    },
    {
      title: "Ditagihkan",
      type: "SUCCESS",
      amount: 0,
      resi: 0,
      loading: false,
      color: "#FFCB01",
      tooltipTitle: this.tooltipTitleBilled
    },
    {
      title: "Telah Dibayar",
      type: "PAID",
      amount: 0,
      resi: 0,
      loading: false,
      color: "#7ACB90",
      tooltipTitle: this.tooltipTitlePaid
    }
  ];
  valueProgressBar(val: number, maxValue: number) {
    return (val / maxValue) * 100;
  }

  get tooltipTitleBilled() {
    return `Data yang ditagihkan merupakan data yang masih belum dibayar atau sedang dalam proses pembayaran (unpaid ataupun partially paid)`;
  }
  get tooltipTitlePaid() {
    return `Data yang telah dibayar merupakan data yang sudah sukses dibayar (paid)`;
  }
}
