import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-row mt-3" }
const _hoisted_2 = { class: "w-64" }
const _hoisted_3 = { class: "grid grid-cols-3 w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_DonutChart = _resolveComponent("DonutChart")!
  const _component_DetailStatusCardDashboardClient = _resolveComponent("DetailStatusCardDashboardClient")!
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_CardDashboardClient = _resolveComponent("CardDashboardClient")!

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_Title, { label: "Ongkos Kirim" }),
    _createVNode(_component_CardDashboardClient, {
      class: "mt-4",
      title: "Detail"
    }, {
      default: _withCtx(() => [
        _createVNode("div", _hoisted_1, [
          _createVNode("div", _hoisted_2, [
            _createVNode(_component_DonutChart, {
              chartSeries: _ctx.chartData,
              chartColor: _ctx.chartColor,
              chartHeight: "150"
            }, null, 8, ["chartSeries", "chartColor"])
          ]),
          _createVNode(_component_DetailStatusCardDashboardClient, {
            class: "w-56 pr-4",
            title: _ctx.totalShippingCost.title,
            loading: _ctx.totalShippingCost.loading,
            value: _ctx.totalShippingCost.amount,
            subValue: _ctx.totalShippingCost.resi,
            isValueCurrency: ""
          }, null, 8, ["title", "loading", "value", "subValue"]),
          _createVNode("div", _hoisted_3, [
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.shippingCostPerStatus, (shippingCost) => {
              return (_openBlock(), _createBlock(_component_DetailStatusCardDashboardClient, {
                class: "px-4 border-l",
                key: shippingCost,
                title: shippingCost.title,
                loading: shippingCost.loading,
                value: shippingCost.amount,
                subValue: shippingCost.resi,
                color: shippingCost.color,
                tooltipTitle: shippingCost.tooltipTitle,
                isValueCurrency: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ProgressBar, {
                    value: 
                _ctx.valueProgressBar(shippingCost.amount, _ctx.totalShippingCost.amount)
              ,
                    class: "w-full mr-2",
                    startValue: 0,
                    endValue: _ctx.totalShippingCost.amount,
                    isFormatMoney: "",
                    customColor: shippingCost.color
                  }, null, 8, ["value", "endValue", "customColor"])
                ]),
                _: 2
              }, 1032, ["title", "loading", "value", "subValue", "color", "tooltipTitle"]))
            }), 128))
          ])
        ])
      ]),
      _: 1
    })
  ]))
}